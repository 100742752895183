import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

import {
    title as prismicTitle,
    richText as prismicRichText,
} from '../shapes/prismic';

import { Layout, Hero, HeroText } from '../components/common';
import { track } from '../services/segment';
import useError from '../util/useError';
import { withPreviewData } from '../util/preview';
import PrismicImage from '../models/prismicImage';
import StaticSigninButton from '../components/common/SignIn/StaticSigninButton';

const useStyles = makeStyles((theme) => ({
    heroOverlay: {
        padding: theme.spacing(3, 0),
    },
    tagLine: {
        paddingBottom: 0,
    },
    services: {
        paddingBottom: 0,
    },
    testimonials: {
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
        },
    },
}));

function Index({ data: staticData, location }) {
    const data = withPreviewData(staticData);

    const {
        title,
        description,
        json_ld: jsonLD,
        hero_image: heroImage,
    } = data.prismicPlatformHomepage.data;

    const classes = useStyles();
    const prismicHeroImage = new PrismicImage(heroImage);
    useError((err) =>
        track('Javascript error', {
            err,
        })
    );

    return (
        <Layout
            location={location}
            customMetaData={{
                title: title.text,
                description: description.text,
                jsonLD: (jsonLD || {}).text,
            }}
        >
            <Hero
                image={prismicHeroImage}
                showOverlay
                classes={{ overlay: classes.heroOverlay }}
            >
                <HeroText
                    title="Benchmark Quotes & Construction Services"
                    subtitle={
                        <>
                            <p>
                                We are no longer offering the Benchmark Quotes
                                service. Thank you to all homeowners,
                                contractors, architects and vendors we&apos;ve
                                worked with to provide Benchmark Quotes.
                            </p>
                            <p>
                                If you have an active Benchmark Quote in process
                                or need warranty support with your remodeling
                                project please reach out by following this link
                                to get in touch.
                            </p>
                        </>
                    }
                    ctaComponent={<StaticSigninButton />}
                />
            </Hero>
        </Layout>
    );
}

export default Index;

Index.propTypes = {
    data: PropTypes.shape({
        prismicPlatformHomepage: PropTypes.shape({
            data: PropTypes.shape({
                title: prismicTitle,
                description: prismicTitle,
                json_ld: prismicRichText,
                hero_image: PropTypes.object.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
    query IndexQuery {
        prismicPlatformHomepage {
            data {
                description {
                    text
                }
                title {
                    text
                }
                json_ld {
                    text
                }
                hero_image {
                    ...ProPrismicImageMetadata_NoBase64
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1920, quality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    }
`;
